@import '../../../styles/styles.scss';

.switch {
    width: 60px;
    min-width: 60px;
    height: 30px;
    background-color: #ccc; 
    border-radius: 15px;
    border-width: 3px;
    border-color: #ccc;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    
  }
  
  .slider {
    width: 24px;
    height: 24px;
    background-color: $white;
    border-radius: 50%;
    position: absolute;
    left: 0;
    transition: left 0.3s;
  }
  
  .switch.on {
    background-color: $primaryColor; /* Background color when switch is on */
    border-color: $primaryColor;
  }
  
  .switch.on .slider {
    left: 30px; /* Move slider to the right when it's on */
  }
  
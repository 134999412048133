@import 'tailwindcss/tailwind.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;400i;500;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

// Colors
$primaryColor: #1450A0;
$secondaryColor: #147814;
$tertiaryColor: #DC7814;
$warningColor: #cc3300;
$successColor: #00cc00;

$featureColor1: #efedee;
$featureColor2: #d2f7ea;
$featureColor3: #b0ecff;
$featureColor4: #f8dabb;
$featureColor5: #ffc5be;
$featureColor6: #ebd7e5;
$featureColor7: #c05670;
$featureColor8: #0a090c;
$featureColor9: #18383b;
$featureColor10: #387fa3;

$textColorDark: #000000;
$textColorLight: #ffffff;
$textColorGrey: #808080;
$textColorPlaceholder: #c0c0c0;

$white: #ffffff;
$black: #000000;
$background: #ffffff;
$backgroundGrey: #f3f3f3;
$backgroundGrey2: #d1d1d1;

$max-width-signin: 1000px;

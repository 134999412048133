
@import './styles.scss';

.container {
    padding: 50px 50px 50px 50px;
}

.homebanner {
    background-color: $featureColor3;

    .images {
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        img {
            max-width: 40%;
        }
    }
}

.feature-section2-1 {
    background-color: $featureColor1;
}

.feature-section2-2 {
    background-color: $featureColor2;
}

.vertical-spacer {
    height: 50px;
}

.horizontal-spacer {
    width: 50px;
}

// {todos.map((item, index) => {
//     return (
//         <Todo
//             description={item.description}
//             done={item.done}
//             key={index}
//             index={index}
//             onChangeTodo={changeTodo}
//             onDeleteTodo={deleteTodo}
//         >
//         </Todo>
//     );
// })}
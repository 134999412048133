@import 'styles.scss';
@import 'textStyles.scss';

.highlighted-info-field {
    
    border-radius: 5px;
    background-color: $white;
    padding: 10px 20px;
    border-left: 6px solid $primaryColor;    
    transition: all 0.3s ease-in-out;
}

.highlighted-info-title {
    font-size: 16px;
    font-weight: 600; // semi-bold
    color: $textColorDark;
}

.highlighted-info-value {
    font-size: 32px;
    font-weight: 600; // semi-bold
    color: $primaryColor;
}

.text-valuation-black {
    font-size: 32px;
    font-weight: 400; 
    color: $textColorDark;
}

.text-valuation-primary {
    font-size: 32px;
    font-weight: 400; 
    color: $primaryColor;
}

.affiliate-quick-dashboard {
    .qualification-text {
        font-size: 16px;
        font-weight: 600; // regular
        color: $secondaryColor;
    }

    .bullet-points {
        font-size: 16px;
        font-weight: 400; // regular
        color: $textColorDark;
        list-style-type: disc;
        padding-left: 20px;
    }
}

.small-horizontal-divider {
    border-bottom: 1px solid $textColorDark;
}

.medium-horizontal-divider {
    border-bottom: 3px solid $backgroundGrey;
}

.thick-horizontal-divider {
    border-bottom: 10px solid $backgroundGrey;
}

@import 'styles.scss';
// @import 'colors.scss';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;400i;500;700&display=swap');
// roboto weights:
// 300 - light
// 400 - regular
// 500 - medium
// 600 - semi-bold
// 700 - bold

h1 {
    font-size: 36px;
    font-weight: 700; // bold
    color: $textColorDark;
    margin: 0px;
}

h2 {
    font-size: 32px;
    font-weight: 400; // regular
    color: $textColorDark;
    margin: 0px;
}

h3 {
    font-size: 24px;
    font-weight: 600; // semi-bold
    color: $textColorDark;
    margin: 0px;
}

h4 {
    font-size: 16px;
    font-weight: 500; // medium
    color: $textColorDark;
    margin: 0px;
}

h5 {
    // Preheader
    font-size: 24px;
    font-weight: 300; // light
    color: $textColorDark;
    margin: 0px;
}

.body-text {
    font-size: 16px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.legal-text {
    font-size: 14px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.create-account-agree-legals {
    font-size: 12px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.create-account-agree-legals-link {
    font-size: 12px;
    font-weight: 400; // regular
    color: $primaryColor;
    text-decoration: underline;
    cursor: pointer;
}

.text-link {
    font-size: 16px;
    font-weight: 500; // medium
    color: $primaryColor;
    cursor: pointer;
}

.alternate-text-link {
    font-size: 16px;
    font-weight: 400; // regular
    color: $textColorDark;
    text-decoration: underline;
    cursor: pointer;
}

.form-link {
    font-size: 20px;
    font-weight: 500; // medium
    color: $primaryColor;
    cursor: pointer;
}

.alternate-form-link {
    font-size: 20px;
    font-weight: 400; // regular
    color: $textColorDark;
    text-decoration: underline;
    cursor: pointer;
}

.data-field-label {
    font-size: 12px;
    font-weight: 400; // regular
    color: $textColorGrey;
}

.data-field-value {
    font-size: 16px;
    font-weight: 600; // regular
    color: $textColorDark;
}

.menu {
    font-size: 16px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.active-tab-title {
    font-size: 14px;
    font-weight: 600; // semi-bold
    color: $textColorDark;
}

.tab-title {
    font-size: 14px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.graph-header {
    font-size: 16px;
    font-weight: 500; // medium
    color: $textColorDark;
}

.graph-tooltip-value {
    font-size: 16px;
    font-weight: 500; // medium
    color: $textColorDark;
}

.graph-tooltip-subtitle {
    font-size: 10px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.graph-tooltip-y-label {
    font-size: 8px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.input-text {
    font-size: 14px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.input-placeholder-text {
    font-size: 14px;
    // font-weight: 400; // regular
    font-style: italic;
    color: $textColorPlaceholder;
}

.input-field-label {
    font-size: 16px;
    font-weight: 600; // medium
    color: $textColorDark;
}

.input-field-label2 {
    font-size: 10px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.input-field-descriptor {
    font-size: 10px;
    font-weight: 400; // regular
    color: $textColorDark;
}

.footer-header {
    font-size: 14px;
    font-weight: 700; // regular
    color: $white;
}

.footer-link {
    font-size: 14px;
    font-weight: 400; // regular
    color: $white;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.footer-text {
    font-size: 12px;
    font-weight: 400; // regular
    color: $white;
}

@import 'styles.scss';
@import 'textStyles.scss';
// @import 'colors.scss';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

// * {
//   font-family: 'Roboto';
// }

// Filled button
.filled-button {
  font-size: 16px;
  font-weight: 600; // semi-bold
  border-radius: 5px;
  background-color: $primaryColor;
  color: $textColorLight; 
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: darken($primaryColor, 10%);
  }
}

.inactive-filled-button {
  font-size: 16px;
  font-weight: 600; // semi-bold
  border-radius: 5px;
  background-color: #f0f0f0; /* Light gray or your preferred inactive background color */
  color: #ccc; /* Light gray text color or your preferred inactive text color */
  padding: 10px 20px;
  border: none;
  cursor: not-allowed; /* Change cursor to indicate it's inactive */
  transition: all 0.3s ease-in-out;
  pointer-events: none; /* Prevent interaction with the button */
}

// Outline button
.outline-button {
  font-size: 16px;
  font-weight: 600; // semi-bold
  border-radius: 5px;
  border: 2px solid $primaryColor;
  color: $primaryColor;
  background-color: $white;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.outline-button:hover {
  border-color: darken($primaryColor, 20%);
  color: darken($primaryColor, 20%);
}

// Text button
.text-button {
  font-size: 16px;
  font-weight: 600; // semi-bold
  border-radius: 5px;
  color: $primaryColor; 
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.text-button-primary {
  font-size: 16px;
  font-weight: 600; // semi-bold
  border-radius: 5px;
  color: $textColorDark;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.text-button-secondary {
  font-size: 16px;
  font-weight: 600; // semi-bold
  border-radius: 5px;
  color: $textColorDark;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.text-button:hover {
  color: darken($primaryColor, 10%);
}


.input-field {
  padding: 10px 15px;
  border: 1px solid $black;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  font-weight: 400;

  &::placeholder {
    color: $textColorPlaceholder;
    font-style: italic;
  }
}

.input-field:focus {
  border-color: $primaryColor;
}

.tab-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab {
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-bottom: 4px solid $white;
  padding: 10px 0;

  &:hover {
    // color: $primaryColor;
    font-weight: 700;
  }
}

.active-tab {
  color: $primaryColor;
  border-bottom: 4px solid $primaryColor;
}

.tab-content {
  background-color: $backgroundGrey;
  padding: 20px;
}


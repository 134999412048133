@import '../../../styles/styles.scss';

.slidermenu-container {
    background-color: $backgroundGrey2;
    padding: 5px 5px;
    border-radius: 100px;
    display: flex;
    flex-direction: row;

    .menu-item {
        padding: 10px;
        border-radius: 100px;
        margin-right: 10px;

        &.active {
            background-color: $white;
        }

        &:hover {
            background-color: $white;
            cursor: pointer;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.slidermenu-container-mobile {
    background-color: $backgroundGrey2;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 25px;
    padding: 5px 5px;
    max-width: fit-content;
    margin-bottom: 25px;

    .menu-item {
        padding: 10px;
        border-radius: 100px;
        margin-bottom: 0px;
        text-align: left;

        &.active {
            background-color: $white;
        }

        &:hover {
            background-color: $white;
            cursor: pointer;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}